.RepositoryContainer {
    border: 1px solid var(--input-border-color);
    min-height: 30px;
    margin: 10px;
    padding: 1px;
}

.RepositoryItem {
    border-bottom: 1px solid var(--ex-green-light);
    padding: 10px 0 10px 10px;
    font-size: 14px;
    font-family: monospace;
    color: var(--ex-green-dark);
    cursor: pointer;
    position: relative;
    background: var(--ex-green-ultralight);
}

.RepositoryItem:hover {
    box-shadow: inset 2px 0 var(--ex-green-light);
    background: var(--ex-green-dark);
    color: var(--ex-green-ultralight);
}

.RepositoryItem:last-child {
    border-bottom: none;
}

.RepositoryItem i {
    color: var(--ex-green-light);
    margin-right: 10px;
}

.RepositoryItem i.fa-file-alt {
    color: var(--btn-bg-color);
}

.RepositoryItemSize {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 12px;
}

.RepositoryReturn {
    color: dimgrey;
    margin-right: 10px;
    font-size: 14px;
    cursor: pointer;
}

.RepositoryMenuBtn {
    border: 1px solid rgb(169, 169, 169);
    padding: 5px 10px;
    box-shadow: 1px 1px 1px #00000085;
    margin-left: 15px;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 10px;
    background-color: var(--btn-bg-color);
    color: var(--input-color);
}

.RepositoryMenuBtn i {
    margin-right: 7px;
}

.blueBorder {
    border: 1px dashed #4cb4ff!important;
}

.RepositoryContextContainer {
    min-width: 130px;
    min-height: 30px;
    border: 1px solid #f5f5f4;
    border-radius: 2px;
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: 2px 3px 5px #0000002b;
    color: #475466;
}

.RepositoryContextItem {
    padding: 6px 0 6px 10px;
    font-size: 12px;
    font-family: monospace;
    color: dimgray;
    cursor: pointer;
    background: white;
}

.RepositoryContextItem i{
    color: darkgrey!important;
    width: 15px;
    margin-right: 5px;
}

.RepositoryContextItem:hover{
    background: #eeeeee;
}





