:root {
    --ex-green-dark: #023332;
    --ex-green-light: #5D8683;
    --ex-green-ultralight: #EEF1F1;

    --ex-grey-light: #676E6D;

    --ex-black: #000;
    --ex-background-grey: #f7f7f7;
}
