
.ObjectListItem {
    width: calc(25% - 20px);
    height: 450px;
    margin: 10px;
    background-color: var(--object-bg-color);
    border-radius: 1px;
    overflow: hidden;
}

.ObjectLIHead {
    position: relative;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
}


.ObjectLIImg {
    width: 100%;
    opacity: 0.75;
}

.ObjectLIHeadShadowBlock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 18px 2px #000000bd;
}

.ObjectLIAuthorAvatar {
    box-shadow: 0 0 3px #00000082;
    width: 28px;
    height: 28px;
    overflow: hidden;
    border-radius: 10px;
    left: 4px;
    top: 4px;
    position: absolute;
    /*z-index: 100;*/
    border: 1px solid #ffffff4a;
    opacity: 0.7;
}

.ObjectLIAuthorAvatar img {
    width: 100%;
}

.ObjectLIDescriptionBlock {
    color: #fff;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
    text-align: center;
}

.ObjectLIFavourite {
    position: absolute;
    left: 10px;
    top: 40px;
    font-size: 14px;
}

.ObjectDisabled {
    position: absolute;
    left: 10px;
    top: 60px;
    font-size: 14px;
    color: darkred;
}

.ObjectLISelection {
    position: absolute;
    right: calc(32px - 0.8em);
    top: 5px;
    font-size: .8em;
    color: white;
    overflow: hidden;
    width: 12px;
    height: 13px;
}

.ObjectLIMenuBtn {
    position: absolute;
    right: 5px;
    top: 5px;
    color: white;
    font-size: .8em;
}

.ObjectLISightsCounter {
    position: absolute;
    left: 6px;
    bottom: 2px;
    font-size: 12px;
    color: #ffffffd9;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
    opacity: 0.7;
}

.ObjectLISightsCounter i {
    margin-left: 4px;
}

.ObjectLILinks {
    position: absolute;
    right: 6px;
    bottom: 1px;
    font-size: 12px;
    text-shadow: 0 0 2px black, 0 0 2px black;
}

/*  Body ... */
.ObjectLIBody {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 230px;
    color: #ffffffc7;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black;
    font-size: 12px;
    font-family: CirceRounded, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 600;
}

.ObjectLIRecordings {
    float: left;
    height: 230px;
    width: 150px;
    border-right: 2px solid #2f2f2f;
    border-left: 2px solid #2f2f2fa8;
    box-shadow: inset -3px 0 5px #00000010;
    padding: 5px 10px;
    overflow-y: overlay;
}

.ObjectLIDescriptions {
    float: left;
    height: 230px;
    width: calc(100% - 150px);
    box-shadow: inset 3px 0 5px #00000010;
    border-right: 2px solid #2f2f2fa8;
    padding: 5px 10px;
    overflow-y: overlay;
}

.ObjectLIFooter {
    clear: left;
    background-color: #2f2f2f;
    height: 20px;
    box-shadow: 0 -1px 7px #00000010, inset 0 -4px 8px #00000030;
    position: relative;
    cursor: default;
}

.ObjectLIStatusBlock {
    position: absolute;
    right: 5px;
    top: 1px;
    font-size: 12px;
}

.ObjectLIDescriptionContainer {
    padding-left: 22px;
    padding-top: 1px;
    position: relative;
    min-height: 21px;
    background-color: #0b0b0b40;
    border: 1px solid #3e3e3ed1;
    border-radius: 5px;
    font-size: 11px;
    margin-top: 5px;
    cursor: default;
}

.ObjectLIDescriptionContainer img {
    position: absolute;
    left: 4px;
    top: 5px;
    width: 13px;
    opacity: 0.8;
}

.ObjectLIDescriptionContainer span {
    margin-top: 2px;
    display: inline-block;
}

/*Speek*/
.ObjectLISpeekContainer {
    height: 28px;
    width: 100%;
    background-color: #282828;
    border-radius: 4px;
    padding: 0 1px;
    margin-top: 6px;
    position: relative;
}

.ObjectLISubSpeek {
    width: 95%!important;
    margin-left: 5%!important;
    border-left: 1px dashed #424242;
    border-radius: 0 4px 4px 0;
    margin-top: 0;
}

.ObjectLISpeekPlayBtn {
    position: absolute;
    z-index: 10;
    left: 3px;
    top: 2px;
    width: 20px;
    height: 20px;
    background-color: #2c2c2ce3;
    border: 2px solid #3e3e3e;
    border-radius: 5px;
}

.ObjectLISpeekPlayBtn img {
    width: 10px;
    opacity: 0.85;
}

.ObjectLISpeekPlayBtn i {
    font-size: 8px;
    color: var(--input-color);
}

.ObjectLISpeekAuthor {
    font-size: 10px;
    height: 14px;
    padding-left: 30px;
    padding-top: 2px;
}

.ObjectLISpeekDate {
    font-size: 9px;
    height: 14px;
    padding-left: 30px;
    text-shadow: none;
    color: var(--input-border-color);
    padding-top: 2px;
    overflow: hidden;
}

.ObjectLISpeekDate b {
    /*color: var(--input-border-color);*/
    color: hsla(0, 0%, 82%, 0.85);
}

.ObjectLIContextBlock {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 30%;
    height: 100%;
    background-color: var(--object-bg-color);
    color: var(--object-author-color);
    box-shadow: -4px 0 14px #000000bf;
    opacity: 0.85;
    padding: 15px 20px;
    font-size: 12px;
    overflow-y: scroll;
    z-index: 5;
}

.ObjectLIContextItem {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
    color: #c8c8c8;
}

.ObjectLIContextItem i {
    position: absolute;
    top: 2px;
    left: 0;
}

.OLIDisabled {
    opacity: 0.8;
    border-radius: 10px;
    border: 2px dashed #ff45008f
}

#SBBlock {
    right: -10px;
    bottom: 0;
    height: 40px;
    position: fixed;
    left: 0;
    padding: 10px;
    background-color: var(--ex-green-dark);
    box-shadow: 0 0 5px #00000040;
    z-index: 599;
}

#SBBlock span {
    margin: 0 20px;
    font-size: 13px;
    color: #e2e2e2;
}

#SBBlock span:first-child {
    margin-left: 5px;
}

#SBBlock i {
    margin-right: 7px;
}

.FBIconButton {
    margin-left: 10px;
    color: var(--object-author-color);
    font-size: 12px;
}

.SpeekFlag {
    margin-right: 4px;
    width: 11px;
}



/* New design */

.ObjectItemContainer {
    flex-basis: calc(20% - 40px);
    margin: 10px 20px 0 20px;
    overflow: hidden;
    position: relative;
}

.OIAvatarContainer {
    position: relative;
}

.OIAvatar {
    width: 100%;
}

.OIDescriptionIcon {
    margin-right: 5px;
}

.OIDescriptionContainer {
    color: var(--ex-grey-light);
    margin-top: 5px;
    font-size: 0.8em;
}

.FBContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/* Search bar */
.SearchBarMain {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.SearchBarMainItem {margin-right: 10px; position: relative}

.SBInput {
    background-color: var(--ex-green-ultralight);
    border: 0;
    padding: 18px 20px 18px 36px;
    border-radius: 30px;
    color: var(--ex-green-dark);
}

.SBInputIcon {
    position: absolute;
    top: 21px;
    left: 21px;
    font-size: 0.8em;
    color: var(--ex-green-dark);
}

.SBButton {
    background-color: var(--ex-green-dark);
    border-radius: 30px;
    color: var(--ex-green-ultralight);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    overflow: hidden;
}
.SBButton img {
    width: 100%;
    height: 100%;
}

.SBPlusMenuBlock {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 65px;
    top: 65px;
    width: 265px;
    background-color: var(--ex-green-ultralight);
    color: var(--ex-green-dark);
    font-size: 1em;
    border-radius: 30px;
    padding: 20px;
    z-index: 10;
    /* box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.1); */
}

.SBPlusMenuItem {
    margin: 10px 10px;
}


