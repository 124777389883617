
.LPContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--ex-green-ultralight);
}

.LPLogo {
    position: absolute;
    top: 2.5em;
    left: 2.5em;
    width: 8em;
}

.LPSignInBlock {
    position: absolute;
    left: 13%;
    top: 25%;
    padding: 4%;
    width: 35%;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
}

.LPSignUpBlock {
    position: absolute;
    right: 13%;
    top: 25%;
    padding: 4%;
    width: 35%;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
}

.LPFormLine {
    display: flex;
    align-items: center;
    margin: 1.2vw 0;
    width: 100%;
}

.LPFormHeader {
    flex-basis: 35%;
    font-size: 0.8em;
    padding-right: 5px;
    color: var(--ex-grey-light);
}

.LPFormInput {
    flex-basis: 65%;

}

.LPInputBlock {
    margin-top: 10px;
}

.LPInput {
    background-color: var(--ex-green-ultralight);
    border: 1px solid var(--ex-green-dark);
    border-radius: 20px;

    padding: 5px 8px;
    margin: 0;
    height: 100%;
    color: var(--ex-green-dark);
    width: 100%;
}

.LPInputHeader {
    display: inline-block;
    border: 1px solid var(--input-border-color);
    border-radius: 5px 0 0 5px;
    border-right: 0;
    padding: 5px;
    font-size: 12px;
    height: 30px;
    overflow: hidden;
    background-color: #0000008c;
    width: 23px;
    color: var(--input-color);
}

.LPInputBody {
    overflow: hidden;
    display: inline-block;
    border: 1px solid var(--input-border-color);
    border-radius: 0 5px 5px 0;
    font-size: 12px;
    height: 30px;
    width: calc(100% - 24px);
}

.LPButton {
    align-self: flex-end;
    width: fit-content;
    font-size: 12px;
    padding: 7px 20px;
    text-align: center;
    border-radius: 20px;
    background-color: var(--ex-green-dark);
}

#generatePassword {
    align-self: flex-end;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: -20px;
    color: var(--ex-grey-light);
}

.LPBlockHeader {
    color: var(--ex-green-dark);
}
