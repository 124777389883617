#OptionsBar {
    padding: .75rem 40px;
    margin-bottom: 5px;
}

.headBtn {
    border: 1px solid var(--input-border-color);
    padding: 5px 12px;
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 4px;
    font-size: 10px;
    background-color: var(--input-placeholder);
    color: var(--input-color);
    display: inline-block;
}

.headBtn i {
    margin-right: 4px;
}

.OBSelect {
    background-color: var(--ex-green-ultralight);
    appearance: none;
    border: 0;
    padding: 10px;
}
