#Header {
    background-color: var(--ex-green-dark);
    flex-basis: 100px;
    height: fit-content;
    padding: 40px 30px 30px 30px;

    display: flex;
    flex-direction: column;
}

#MainLogo {
    display: flex;
    align-items: center;
}

#MainLogo img {
    height: 23px;
    margin-right: 40px;
}

#MainMenuHamburger {
    font-size: 20px;
}

#MainMenu {
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.MainMenuItem {
    color: white!important;
    text-decoration: none;
    font-size: 15px;
    margin: 15px 0 15px 0;
    display: inline-block;
    position: relative;
}

.MainMenuItem:hover {
    color: var(--ex-green-light) !important;
}

.MMPendingAlert {
    color: var(--object-author-color);
    font-size: 9px;
    position: absolute;
}

.MMNotificationLabel {
    position: absolute;
    top: -11px;
    right: -16px;
    background: rgba(184, 134, 11, 0.76);
    padding: 2px 7px;
    border-radius: 25px;
}
