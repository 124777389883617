.ProjectListBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ProjectListItem {
    margin: 5px;
    border: 2px solid #0000006b;
    border-radius: 3px;
    padding: 5px;
    position: relative;
    width: calc(50% - 10px);
    height: 85px;
    cursor: pointer;
    background-color: rgba(138, 138, 138, 0.25);
}

.ProjectListItem:hover {
    box-shadow: inset 0 0 20px #b5b5b56b;
}

.ProjectListItem .fa-bell {
    color: goldenrod;
}

.ProjectItemLeft {
    width: 80px;
    height: 100%;
    float: left;
}

.ProjectItemRight {
    width: calc(100% - 100px);
    height: 100%;
    float: left;
    line-height: 1.5;
}

.ProjectItemAlertBlock {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 12px;
}

.ProjectIconBlock {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 40px;
}

.ProjectIconBlock i {
    font-size: 50px;
    color: #7f7f7fd4;
    margin-top: 7px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.ProjectIconBlock img {
    width: 100%;
    height: 100%;
}

.ProjectItemName {
    font-weight: 700;
    overflow: hidden;
    height: 24px;
}

.ProjectItemLastVisit {
    font-size: 14px;
    font-weight: 200;
}

.ProjectItemRegistered {
    color: #9E9E9E;
    font-size: 14px;
    font-weight: 200;
}

.ProjectCreateForm {
    overflow: auto;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-bg-color);
    padding: 30px;
    border-radius: 4px;
    font-size: 12px;
}

.ProjectCreateForm .DoubleInput, .ProjectCreateForm .DoubleButton {
    width: 100%!important;
}

.ProjectCreateForm b{
    font-size: 16px;
}


.GroupInvitationsBlock {
    padding: 5px 20px 10px 20px;
    background-color: rgba(156, 128, 14, 0.67);
    border-radius: 10px;
    margin: 0 5px;
    position: relative;
}

.GroupInvitationsItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.GIIAvatarBlock {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 40px;
    margin-right: 15px;
    background-color: rgba(0, 0, 0, 0.13);
}

.GIIAvatarBlock i {
    font-size: 35px;
    margin-top: 7px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.GIITextBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.GIIButtonsBlock {
    display: flex;
    flex-direction: row;
    height: 35px;
    align-self: center;
}

.GIIButtonsBlock div {
    height: 100%!important;
    padding-top: 7px!important;
}

/*New design*/
.PLItem {

}

