.EditorOption {
    padding: 8px;
    position: relative;
    min-height: 22px;
    background-color: #0b0b0b40;
    border: 1px solid #3e3e3ed1;
    border-radius: 5px;
    font-size: 11px;
    margin-top: 5px;
    cursor: default;
    line-height: 1.8;
}

.FullWidthOption {
    width: 95%;
    overflow: hidden;
    position: relative;
}

.FullWidthOption .ObjectSelectorBlock {
    margin: 0 10px 0 0;
}

.FullWidthOption .ObjectSelectorText {
    padding-top: 10px;
}

.OptionKey {
    width: 35%;
    display: inline-block;
    /*margin: 3px 0;*/
    position: relative;
}

.OptionValue {
    width: 60%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    /*margin-top: 1px;*/
}

.EditorInput {
    background-color: var(--btn-bg-color);
    border: 1px solid var(--input-border-color);
    border-radius: 5px;
    padding-left: 5px;
    color: var(--input-color);
    width: 100%;
}

.OptionSelect {
    height: 20px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    font-size: 11px;
    padding-left: 3px;
    background-color: var(--btn-bg-color);
    color: var(--input-color);
    width: 100%;
}

.EditorInput::placeholder {
    color: var(--input-placeholder);
}

.ObjectAvatarBlock, .ObjectUploadAvatarPageBtn {
    position: absolute;
    right: 3px;
    top: 0;
    height: 20px;
    width: 20px;
    overflow: hidden;
    border: 0.5px solid var(--input-border-color);
    border-radius: 3px;
}

.ObjectAvatarImg {
    width: 100%;
    height: 100%;
}

.FBSearchBlock {
    margin: 0 30px;
    font-size: 12px;
    display: inline-block;
}

.FBSearchBlock i {
    color: #857876;
    margin-left: 10px;
}

.FBSearchLine {
    border-radius: 4px;
    background-color: #ffffffc4;
    border: 0;
    width: 200px;
    padding: 3px 0 3px 6px;
    color: darkslategrey;
    font-size: 12px;
}

.FBCleanAll {
    margin-left: 10px;
    color: grey;
    font-size: 13px;
}

.FBCleanAll i {
    margin-left: 1px;
}

.PublicIdMenu {
    padding: 6px 0 6px 15px!important;
}

.PublicIdMenu i {
    margin: 0 15px 0 0!important;
}

.PublicIdMenuButton {
    top: 12px!important;
    right: 10px!important;
}
