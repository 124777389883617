.ObjectListBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 30px;
  margin-bottom: 20px; }


.OLBDownloadButton {
  width: calc(100% - 80px);
  margin: 20px 40px;
  height: 50px;
  cursor: pointer; }

